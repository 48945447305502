import * as types from "./actionTypes";
import { fetchWithBodyAndDispatch, fetchWithDispatch } from "../lib/fetch";
import { fetchShoppingCartHeader } from "./shoppingCartHeader";

export const toggleSupplierSelection = (supplier, manufacturer) => ({
  type: types.TOGGLE_SUPPLIER_SELECTION,
  supplier,
  manufacturer,
});

export const toggleSupplierRemoval = (manufacturer, supplier) => ({
  type: types.TOGGLE_SUPPLIER_REMOVAL,
  supplier,
  manufacturer,
});

export const toggleAllSuppliersRemoval = () => ({
  type: types.TOGGLE_ALL_SUPPLIERS_REMOVAL
});

export const changeSupplierCount = (nomenclature, supplier, amount, count) => ({
  type: types.CHANGE_SUPPLIER_COUNT,
  nomenclature,
  supplier,
  amount,
  count
});

export const fetchSuppliers = commodityCode => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.FETCH_SUPPLIERS_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/supplier?commodity=${commodityCode}`,
    json =>
      dispatch({
        type: types.FETCH_SUPPLIERS_SUCCESS,
        response: json
      }),
    error =>
      dispatch({
        type: types.FETCH_SUPPLIERS_FAILURE,
        error
      })
  );
};

export const fetchSupplierHeader = commodityCode => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.FETCH_SUPPLIER_HEADER_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}/supplier`,
    json =>
      dispatch({
        type: types.FETCH_SUPPLIER_HEADER_SUCCESS,
        response: json
      }),
    error =>
      dispatch({
        type: types.FETCH_SUPPLIER_HEADER_FAILURE,
        error
      })
  );
};

export const batchSaveSupplierHeader = (
  commodityCode,
  updateItems,
  deleteItems = [],
  { undo = false } = {}
) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.BATCH_SAVE_SUPPLIER_HEADER_REQUEST,
    commodityCode,
    updateItems,
    deleteItems,
    undo
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}/supplier/batch`,
    {
      method: "PUT",
      body: {
        update: updateItems,
        delete: deleteItems
      }
    },
    json => {
      dispatch({
        type: types.BATCH_SAVE_SUPPLIER_HEADER_SUCCESS,
        response: json,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
      dispatch(fetchSupplierOverview(commodityCode));
    },
    error => {
      dispatch({
        type: types.BATCH_SAVE_SUPPLIER_HEADER_FAILURE,
        error,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
    }
  );
};

export const saveSupplierItem = (commodityCode, itemId, supplierId, amount, manufacturerId) => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.SAVE_SUPPLIER_ITEM_REQUEST,
    commodityCode,
    itemId,
    supplierId,
    manufacturerId,
    amount
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}/supplier/${itemId}`,
    {
      method: "PATCH",
      body: {
        amount,
        supplier_id: supplierId,
        manufacturer_id: manufacturerId
        // manufacturer_id: supplierId,
      }
    },
    json => {
      dispatch({
        type: types.SAVE_SUPPLIER_ITEM_SUCCESS,
        response: json,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
    },
    error => {
      dispatch({
        type: types.SAVE_SUPPLIER_ITEM_FAILURE,
        error,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
    }
  );
};

export const batchRemoveSupplierHeader = (commodityCode, items) => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.BATCH_REMOVE_SUPPLIER_HEADER_REQUEST,
    commodityCode,
    items
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}/supplier/batch`,
    {
      method: "DELETE",
      body: {
        items: items
      }
    },
    json => {
      dispatch({
        type: types.BATCH_REMOVE_SUPPLIER_HEADER_SUCCESS,
        response: json,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
      dispatch(fetchSupplierOverview(commodityCode));
    },
    error => {
      dispatch({
        type: types.BATCH_REMOVE_SUPPLIER_HEADER_FAILURE,
        error,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
    }
  );
};

export const fetchSupplierOverview = commodityCode => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.FETCH_SUPPLIER_OVERVIEW_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/supplier/overview${commodityCode ? `?commodity=${commodityCode}` : ''}`,
    json =>
      dispatch({
        type: types.FETCH_SUPPLIER_OVERVIEW_SUCCESS,
        response: json
      }),
    error =>
      dispatch({
        type: types.FETCH_SUPPLIER_OVERVIEW_FAILURE,
        error
      })
  );
};

export const undoState = () => (dispatch, getState, { apiUrl }) => {
  const undoStates = getState().suppliers.undoStates;
  const undoAction = undoStates[undoStates.length - 1];
  return (
    undoAction &&
    dispatch(undoAction).then(() =>
      dispatch({
        type: types.SUPPLIERS_POP_STATE
      })
    )
  );
};

export const clearState = () => ({
  type: types.SUPPLIERS_CLEAR_STATE
});

export const duplicateNomenclatureSuppliers = (
  commodityCode,
  itemId,
  supplierId,
  manufacturerId,
) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.DUPLICATE_SUPPLIER_ITEM_REQUEST
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}/supplier/${itemId}/duplicate`,
    {
      method: "PUT",
      body: {
        supplier_id: supplierId,
        manufacturer_id: manufacturerId,
      }
    },
    json => {
      dispatch({
        type: types.DUPLICATE_SUPPLIER_ITEM_SUCCESS,
        response: json,
        commodityCode,
        itemId,
        supplierId,
        manufacturerId
      });
      dispatch(fetchSupplierHeader(commodityCode));
      dispatch(fetchShoppingCartHeader(commodityCode));
      dispatch(fetchSupplierOverview(commodityCode));
    },
    error => {
      dispatch({
        type: types.DUPLICATE_SUPPLIER_ITEM_FAILURE,
        error,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
    }
  );
};

export const deduplicateNomenclatureSuppliers = (
  commodityCode,
  itemId,
  supplierId
) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.DEDUPLICATE_SUPPLIER_ITEM_REQUEST
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}/supplier/${itemId}/duplicate`,
    {
      method: "DELETE",
      body: {
        supplier_id: supplierId
      }
    },
    json => {
      dispatch({
        type: types.DEDUPLICATE_SUPPLIER_ITEM_SUCCESS,
        response: json,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
      dispatch(fetchShoppingCartHeader(commodityCode));
    },
    error => {
      dispatch({
        type: types.DEDUPLICATE_SUPPLIER_ITEM_FAILURE,
        error,
        commodityCode
      });
      dispatch(fetchSupplierHeader(commodityCode));
    }
  );
};

import React, { useState } from "react";
import { AlignLeft, List } from "react-feather";

const CommoditySuppliers = ({ suppliers }) => {
  const [listView, setListView] = useState();
  const {
    allSuppliers
  } = suppliers;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gridGap: '1rem' }}>
        <h2>Dodavatelé</h2>
        {allSuppliers.length > 1 && (
          <div style={{ display: 'flex' }}>
            <div onClick={() => setListView(false)} style={{ height: '24px', padding: '.2rem', background: !listView ? '#ddd' : null }}>
              <AlignLeft />
            </div>
            <div onClick={() => setListView(true)} style={{ height: '24px', padding: '.2rem', background: listView ? '#ddd' : null }}>
              <List />
            </div>
          </div>
        )}
      </div>
      {allSuppliers.length ? (
        listView ? (
          <ul>
            {allSuppliers.map(item => (
              <li key={item.id}>
                <strong>{item.name}</strong>
                {(item.Manufacturers && item.Manufacturers.length) ? (
                  <ul style={{ color: '#999' }}>
                      {item.Manufacturers.map((manufacturer, index) => <li key={index}>{manufacturer.name}</li>)}
                  </ul>
                ) : ''}
              </li>
            ))}
          </ul>
        ) : (
          allSuppliers.map((item, index, arr) => {
            item.Manufacturers = item.Manufacturers || []
            const manufacturerList = item.Manufacturers.map(manufacturer => manufacturer.name).join(', ')
            return <span key={index}><strong>{item.name}</strong> {manufacturerList.length
              ? (<span style={{ color: '#999' }}>({manufacturerList})</span>)
              : ''}{index === arr.length - 1 ? '' : ', '}
            </span>
          })
        )
      ) : null}
    </div>
  )
}

export default CommoditySuppliers;
import React, { useEffect, useMemo, useState, useRef } from "react";
import { connect } from "react-redux";
import "./Commodity.css";
import { prepareNomenclaturesDataForSupplier } from "../reducers/nomenclatures";
import Header from "../components/Header";
import ShoppingCart from "../components/ShoppingCart";
import {
  ChevronLeft,
  Copy,
  CornerDownLeft,
  Edit2,
  HardDrive,
  Plus,
  Trash2,
  X
} from "react-feather";
import { colorImageUrl } from "../lib/urls";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import s from "./Supplier.module.css";
import {
  changeSupplierCount,
  clearState,
  fetchSupplierHeader,
  fetchSupplierOverview,
  fetchSuppliers,
  saveSupplierItem,
  toggleAllSuppliersRemoval,
  toggleSupplierRemoval,
  toggleSupplierSelection,
  undoState,
  duplicateNomenclatureSuppliers,
  batchSaveSupplierHeader
} from "../actions/suppliers";
import classNames from "classnames";
import ColorImg from "../components/ColorImg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./Supplier.css";
import { fetchShoppingCart } from "../actions/shoppingCart";
import { fetchInitialNomenclatures } from "../actions/nomenclatures";
import { fetchShoppingCartHeader } from "../actions/shoppingCartHeader";
import { formatNumber } from "../lib/numbers";
import _ from "lodash";
import {
  addSupplierToNomenclature,
  addSupplierToNomenclatures,
  swapNomenclatureSuppliers,
  colorGroupOnClick,
  commodityOnClick,
  filterAllowedSuppliers,
  MODES,
  nomenclatureOnClick
} from "./Supplier/helpers";
import { ReactComponent as Truck } from "../images/truck.svg";
import { roundValueUpByPiecesInBox } from "./Commodity/cellRenders";
import { trackEvent } from "../lib/ga";
import Popup from "react-popup";

function DouplicateConfirmPopup({ onConfirm }) {
  return (
    <div className={s.confirmPopup}>
      <div className={s.confirmPopupHeadingIconWrap}>
        <Copy className={s.confirmPopupHeadingIcon} size="20" color="#FFF" />
      </div>
      <div>
        <div className={s.confirmPopupHeading}>
          <div className={s.confirmPopupHeadingTitle}>
            Duplikace množství k nákupu
          </div>
        </div>
        <div className={s.confirmPopupHeadingBody}>
          Duplikací množství přiřazeného k dodavateli{" "}
          <strong>změníte nákupní rozhodnutí</strong>. Přejete si i přesto
          pokračovat?
        </div>
        <div className={s.confirmPopupActions}>
          <button
            className={s.confirmPopupActionsSecondary}
            onClick={() => {
              Popup.close();
            }}
          >
            Zrušit
          </button>
          <button
            className={s.confirmPopupActionsPrimary}
            onClick={() => {
              trackEvent("duplicate-supplier", "update");
              onConfirm();
              Popup.close();
            }}
          >
            Změnit nákupní rozhodnutí
          </button>
        </div>
      </div>
    </div>
  );
}

function CommodityRow({
  commodity,
  selectedSupplier,
  selectedManufacturer,
  nomenclatures,
  dispatch,
  selectedMode,
  suppliers,
  suppliersOverview,
  suppliersPerNomenclature,
  allowedNomenclaturesBySupplier
}) {
  const finishedNomCount = nomenclatures.length;
  const completedNomCount = useMemo(
    () =>
      nomenclatures.reduce(
        (cnt, nom) => cnt + (nom.supplierState === "completed" ? 1 : 0),
        0
      ),
    [nomenclatures]
  );
  const allowedSuppliers = useMemo(
    () =>
      filterAllowedSuppliers(
        nomenclatures,
        suppliers,
        allowedNomenclaturesBySupplier
      ),
    [nomenclatures, suppliers, allowedNomenclaturesBySupplier]
  );

  return (
    <tr
      className={classNames(s.commodityRow, {
        [s.nomRowSelection]: selectedMode
      })}
      onClick={() => {
        if (selectedMode)
          dispatch(
            commodityOnClick(
              selectedMode,
              selectedSupplier,
              commodity.code,
              nomenclatures,
              suppliersPerNomenclature,
              allowedNomenclaturesBySupplier,
              selectedManufacturer,
            )
          );
      }}
    >
      <td>
        {allowedSuppliers.length > 0 && (
          <SuppliersMenu
            suppliers={allowedSuppliers}
            onItemClick={(supplierId, manufacturerId) =>
              dispatch(
                addSupplierToNomenclatures(
                  nomenclatures,
                  suppliersPerNomenclature,
                  supplierId,
                  commodity.code,
                  allowedNomenclaturesBySupplier,
                  manufacturerId
                )
              )
            }
          />
        )}
      </td>
      <td className={s.borderRight} colSpan="2">
        <div className={s.commodityNameWrap}>
          <span className={s.commodityCode}>{commodity.code}</span>
          <span className={s.commodityName}>{commodity.name}</span>
        </div>
      </td>
      <td colSpan='2'>
        {suppliersOverview.pending && <div className={s.supplierWrap}>Načítání...</div>}
        {suppliersOverview && !suppliersOverview.pending && suppliersOverview.rows.map(supplier => (
          <div key={supplier.id} className={s.supplierWrap}>
            <span><span>{supplier.name}:&ensp;</span><strong>{formatNumber(supplier.piecesSum)} ks ({formatNumber(supplier.valueSum)})</strong></span>
          </div>
        ))}
      </td>
      <td
        className={classNames(
          s.totalCounts,
          completedNomCount === finishedNomCount
            ? s.completedCount
            : s.inProgressCount
        )}
      >
        <Truck className={s.totalCountsIcon} />
        <div>
          {completedNomCount}/{finishedNomCount}
        </div>
      </td>
    </tr>
  );
}

function ColorRow({
  commodityCode,
  color,
  selectedSupplier,
  selectedManufacturer,
  nomenclatures,
  dispatch,
  selectedMode,
  suppliers,
  suppliersPerNomenclature,
  supplierState,
  allowedNomenclaturesBySupplier
}) {
  const allowedSuppliers = useMemo(
    () =>
      filterAllowedSuppliers(
        nomenclatures,
        suppliers,
        allowedNomenclaturesBySupplier
      ),
    [nomenclatures, suppliers, allowedNomenclaturesBySupplier]
  );

  return (
    <tr
      className={classNames(s.colorRow, s.sumRow, {
        [s.nomRowSelection]: selectedMode,
        [s.notEligibleManufacturer]:
          selectedMode === MODES.SELECTION &&
          !allowedSuppliers.find(sup => sup.Manufacturers.find(m => m.id === selectedManufacturer))
      })}
      onClick={() => {
        if (selectedMode)
          dispatch(
            colorGroupOnClick(
              selectedMode,
              commodityCode,
              nomenclatures,
              selectedSupplier,
              suppliersPerNomenclature,
              allowedNomenclaturesBySupplier,
              selectedManufacturer,
            )
          );
      }}
    >
      <td>
        {allowedSuppliers.length > 0 && (
          <SuppliersMenu
            suppliers={allowedSuppliers}
            onItemClick={(supplierId, manufacturerId) =>
              dispatch(
                addSupplierToNomenclatures(
                  nomenclatures,
                  suppliersPerNomenclature,
                  supplierId,
                  commodityCode,
                  allowedNomenclaturesBySupplier,
                  manufacturerId
                )
              )
            }
          />
        )}
      </td>
      <td colSpan="2">
        <div className={s.nomName}>
          <ColorImg
            className={s.nomColor}
            width="28"
            height="28"
            alt={color.color_code}
            color={color.color_code}
            src={colorImageUrl(color.color_code)}
          />
          <span className={s.colorLabel}>BARVA</span>
        </div>
      </td>
      <td />
      <td className={s.colorForOrderColl}>{formatNumber(color.for_order)}</td>
      <td>
        <div
          className={classNames(s.colorCompletionsStatus, s[supplierState])}
        />
      </td>
    </tr>
  );
}

function NomenclatureRow({
  commodity,
  nom,
  selectedSupplier,
  selectedManufacturer,
  dispatch,
  selectedMode,
  suppliers,
  suppliersPerNomenclature = [],
  supplierState,
  allowedNomenclaturesBySupplier
}) {
  const allowedSuppliers = React.useMemo(
    () =>
      suppliers.filter(sup => {
        return allowedNomenclaturesBySupplier[sup.id].has(nom.nomenclature_id);
      }),
    [suppliers, allowedNomenclaturesBySupplier, suppliersPerNomenclature]
  );

  const itemToRemove = (suppliersPerNomenclature[nom.nomenclature_id] || []).find(sup => sup.supplier_id === selectedSupplier && sup.manufacturer_id === selectedManufacturer)

  return (
    <tr
      className={classNames(s.nomRow, {
        [s.nomRowSelection]: selectedMode,
        [s.notEligibleManufacturer]:
          (selectedMode === MODES.SELECTION &&
          !allowedSuppliers.find(sup => sup.Manufacturers.find(m => m.id === selectedManufacturer)))
          || (selectedMode === MODES.REMOVAL && !itemToRemove)
      })}
      onClick={() => {
        if (selectedMode) {
          if (selectedMode === MODES.REMOVAL && !itemToRemove) return
          dispatch(
            nomenclatureOnClick(
              selectedMode,
              commodity.code,
              nom,
              selectedSupplier,
              selectedManufacturer,
              suppliersPerNomenclature,
              allowedNomenclaturesBySupplier,
            )
          );
        }
      }}
    >
      <td>
        {nom.for_order > 0 && allowedSuppliers.length > 0 && (
          <SuppliersMenu
            suppliers={allowedSuppliers}
            onItemClick={(supplierId, manufacturerId) =>
              dispatch(
                addSupplierToNomenclature(
                  nom,
                  supplierId,
                  suppliersPerNomenclature[nom.nomenclature_id],
                  commodity.code,
                  allowedNomenclaturesBySupplier,
                  manufacturerId,
                )
              )
            }
          />
        )}
      </td>
      <td className={s.nomColorSizeCol}>
        <div className={s.nomColorSize}>
          <ColorImg
            className={s.nomColor}
            width="28"
            height="28"
            alt={nom.color_code}
            color={nom.color_code}
            src={colorImageUrl(nom.color_code)}
          />
          <span className={s.nomSize}>{nom.size}</span>
        </div>
      </td>
      <td className={s.borderRight}>
        <div className={s.nomName}>
          <span className={s.nomId}>{nom.id}</span>
        </div>
      </td>
      <td />
      <td className={s.nomForOrderColl}>{formatNumber(nom.for_order)}</td>
      <td>
        <div className={classNames(s.nomCompletionsStatus, s[supplierState])} />
      </td>
    </tr>
  );
}

export function SuppliersMenu({ suppliers, onItemClick, iconComponent }) {
  const [isOpened, setOpened] = useState(false);
  const listRef = useRef();

  return (
    <div
      className={s.addSupplier}
    >
      <button
        type="button"
        className={s.supplierLineIconButton}
        onClick={() => {
          setOpened(!isOpened);
        }}
        onBlur={e => {
          if (e.relatedTarget && !listRef.current.contains(e.relatedTarget)) {
            setOpened(false)
          }
        }}
      >
        {iconComponent || <Plus className={s.plusIcon} />}
      </button>
      {isOpened && (
        <ul ref={listRef} className={s.suppliersMenu}>
          {suppliers.map(sup => sup.Manufacturers.map(manufacturer =>
            <li
              tabIndex="0"
              className={s.suppliersMenuItem}
              onClick={e => {
                console.log({e})
                e.stopPropagation();
                onItemClick(sup.id, manufacturer.id);
                setOpened(false);
              }}
              key={manufacturer.id}
            >
              {manufacturer.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function CountInput({
  commodityCode,
  supplier,
  dispatch,
  step,
  suppliers,
  nomenclature
}) {
  const { amount, supplier_id, manufacturer_id, id } = supplier;

  return (
    <input
      type="number"
      className={`${s.numberInput}`}
      value={amount}
      min={0}
      step={step}
      onClick={e => {
        e.target.select();
      }}
      onChange={e =>
        dispatch(
          changeSupplierCount(
            supplier.nomenclature_id,
            supplier_id,
            e.target.value
          )
        )
      }
      onKeyPress={ev => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          ev.target.blur();
        }
      }}
      onBlur={e => {
        const newValue = roundValueUpByPiecesInBox(e.target.value, step);
        if (newValue !== amount) {
          suppliers.length === 2
            ? dispatch(
                batchSaveSupplierHeader(
                  commodityCode,
                  suppliers.map(sup => {
                    const newAmount =
                      sup.supplier_id === supplier_id
                        ? newValue
                        : nomenclature.for_order - newValue;
                    return {
                      amount: newAmount,
                      supplier_id: sup.supplier_id,
                      nomenclature_id: nomenclature.nomenclature_id,
                      manufacturer_id: sup.manufacturer_id || sup.supplier_id,
                    };
                  })
                )
              )
            : dispatch(
                saveSupplierItem(commodityCode, id, supplier_id, newValue, manufacturer_id || supplier_id)
              );
        }
      }}
    />
  );
}

function CommodityHeader({ fetching, selectedCommodity }) {
  return (
    <section className={s.commodityNameHeading}>
      <Link className={s.commodityBackLink} to="/shopping-cart">
        <ChevronLeft className="backIcon" size="24" />
        Zpět do košíku
      </Link>
      <h1>
        {fetching ? (
          "Načítání..."
        ) : (
          <>
            <div className={s.screenTitle}>
              <span className={s.screenTitleComCode}>
                {selectedCommodity.code}
              </span>{" "}
              <span className="name">{selectedCommodity.name}</span>
            </div>
          </>
        )}
      </h1>
      <div>&nbsp;</div>
      {/*<Link className={s.goToSuppliersBtn} to={`/suppliers-overview`}>
        Přehled dodavatelů
      </Link>*/}
    </section>
  );
}

function SupplierRow({
  commodityCode,
  allSuppliersById,
  allManufacturersById,
  supplier,
  nomenclature,
  supplierId,
  dispatch,
  suppliers,
  allowedNomenclaturesBySupplier,
  suppliersPerNomenclature
}) {

  const elRef = useRef()

  const [hovering, setHovering] = useState(false);
  const [isOpened, setOpened] = useState(false);
  useEffect(() => {
    function clickListener(e) {
      if (!elRef.current.contains(e.target)) {
        console.log('close')
        setOpened(false)
      }
    }

    if (isOpened) {
      window.addEventListener('click', clickListener)
    } else {
      window.removeEventListener('click', clickListener)
    }

    return () => {
      window.removeEventListener('click', clickListener)
    }
  }, [isOpened])

  const allowedSuppliers = React.useMemo(
    () =>
      suppliers.filter(sup => {
        return (
          allowedNomenclaturesBySupplier[sup.id].has(
            nomenclature.nomenclature_id
          ) &&
          !(suppliersPerNomenclature[nomenclature.nomenclature_id] || []).find(
            assignedSup => assignedSup.supplier_id === sup.id
          )
        );
      }),
    [suppliers, allowedNomenclaturesBySupplier, suppliersPerNomenclature]
  );
  const assignedSuppliers =
    suppliersPerNomenclature[nomenclature.nomenclature_id];

  const name = _(allManufacturersById).get([supplier.manufacturer_id, "name"]) || _(allSuppliersById).get([supplierId, "name"])

  return (
    <tr
      className={s.supplierRow}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => {
        setHovering(false);
      }}
      ref={elRef}
    >
      <td />
      <td />
      <td className={s.borderRight} />
      <td>
        <div className={s.supplierWrap}>
          {name}
          {(hovering || isOpened) && (
            <div className={`${s.supplierLineButtons} ${isOpened ? s.supplierLineButtonsActive : ''}`}>
              {allowedSuppliers.length > 0 && (
                <>
                  <SuppliersMenu
                    suppliers={allowedSuppliers}
                    onItemClick={(supplierId, manufacturerId) => {
                      console.log({supplierId, manufacturerId})
                      dispatch(
                        swapNomenclatureSuppliers({
                          commodityCode,
                          nomenclatureId: nomenclature.nomenclature_id,
                          originSupplier: supplier.supplier_id,
                          originManufacturer: supplier.manufacturer_id || supplier.supplier_id,
                          targetSupplier: supplierId,
                          targetManufacturer: manufacturerId,
                          amount: supplier.amount
                        })
                      );
                      setOpened(false);
                      setHovering(false);
                    }}
                    iconComponent={
                      <Edit2
                        className={s.editNomBtn}
                        onClick={() => setOpened(true)}
                      />
                    }
                  />
                  <SuppliersMenu
                    suppliers={allowedSuppliers}
                    onItemClick={(supplierId, manufacturerId) => {
                      console.log({supplierId, manufacturerId})
                      Popup.create({
                        content: (
                          <DouplicateConfirmPopup
                            onConfirm={() =>
                              dispatch(
                                duplicateNomenclatureSuppliers(
                                  commodityCode,
                                  supplier.id,
                                  supplierId,
                                  manufacturerId,
                                )
                              )
                            }
                          />
                        )
                      });
                      setOpened(false);
                      setHovering(false);
                    }}
                    iconComponent={
                      <Copy
                        className={s.dupNomBtn}
                        onClick={() => setOpened(true)}
                      />
                    }
                  />
                </>
              )}
              <span>
                <button
                  className={`${s.supplierLineIconButton} ${s.removeNomBtn}`}
                  onClick={() =>
                    dispatch(
                      nomenclatureOnClick(
                        MODES.REMOVAL,
                        commodityCode,
                        nomenclature,
                        supplier.supplier_id,
                        supplier.manufacturer_id,
                        suppliersPerNomenclature,
                        allowedNomenclaturesBySupplier
                      )
                    )
                  }
                >
                  <X />
                </button>
              </span>
            </div>
          )}
        </div>
      </td>
      <td className={s.supplierForOrderCol}>
        <CountInput
          supplier={supplier}
          suppliers={assignedSuppliers}
          commodityCode={commodityCode}
          dispatch={dispatch}
          step={nomenclature.pices_in_box}
          nomenclature={nomenclature}
        />
      </td>
      <td />
    </tr>
  );
}

function isSupplierSelectedForRemoval(
  supplierId,
  selectedSupplier,
  selectedMode,
  manufacturerId,
  selectedManufacturer
) {
  return supplierId === selectedSupplier && manufacturerId === selectedManufacturer && selectedMode === MODES.REMOVAL;
}

function Supplier({
  dispatch,
  match,
  errors,
  fetching,
  data,
  selectedCommodity,
  shoppingCart,
  suppliers,
  suppliersOverview
}) {
  const { nomenclaturesByColor, colors, nomenclatures } = data;
  const {
    suppliersPerNomenclature,
    selectedSupplier,
    selectedManufacturer,
    selectedMode,
    allSuppliers,
    allSuppliersById,
    allManufacturersById,
    suppliersCount,
    manufacturersCount = {},
    allowedNomenclaturesBySupplier
  } = suppliers;

  useEffect(() => {
    const commodityCode = match.params.id;
    dispatch(fetchShoppingCart());
    dispatch(fetchInitialNomenclatures(commodityCode));
    dispatch(fetchShoppingCartHeader(commodityCode));
    dispatch(fetchSuppliers(commodityCode));
    dispatch(fetchSupplierHeader(commodityCode));
    dispatch(fetchSupplierOverview(commodityCode));
    return () => dispatch(clearState());
  }, []);

  return (
    <div>
      <Header />
      <ShoppingCart
        shoppingCart={shoppingCart}
        isFetching={fetching.shoppingCart}
        error={errors.shoppingCart}
      />
      <div className="fixedMaxSize">
        <CommodityHeader
          fetching={fetching.nomenclatures}
          selectedCommodity={selectedCommodity}
        />
        <div className={s.grid}>
          <section className={s.nomenclatures}>
            <div className={s.nomenclaturesHeader}>
              <div className={s.nomenclaturesHeaderIcon}>
                <HardDrive color="#FFF" size="18" />
              </div>
              <h2 className={s.nomenclaturesHeading}>Nomenklatury</h2>
            </div>

            <table className={s.nomSuppliersTable}>
              <thead>
                <tr className={s.headerRow}>
                  <th className={s.plusColl} />
                  <th
                    className={`${s.nomThText} ${s.nomenclatureColl}`}
                    colSpan="2"
                  >
                    Nomenklatura
                  </th>
                  <th className={`${s.nomThText} ${s.supplierColumn}`}>
                    {/*Dodavatel*/}
                    Výrobce
                  </th>
                  <th className={s.nomThNumber}>Počet Ks</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <CommodityRow
                  commodity={selectedCommodity}
                  selectedSupplier={selectedSupplier}
                  selectedManufacturer={selectedManufacturer}
                  nomenclatures={nomenclatures}
                  dispatch={dispatch}
                  selectedMode={selectedMode}
                  suppliers={allSuppliers}
                  suppliersOverview={suppliersOverview}
                  suppliersPerNomenclature={suppliersPerNomenclature}
                  allowedNomenclaturesBySupplier={
                    allowedNomenclaturesBySupplier
                  }
                />
                {colors &&
                  colors.map(color => (
                    <React.Fragment key={`${color.color_code}`}>
                      <ColorRow
                        commodityCode={selectedCommodity.code}
                        color={color}
                        selectedSupplier={selectedSupplier}
                        selectedManufacturer={selectedManufacturer}
                        nomenclatures={nomenclaturesByColor[color.color_code]}
                        dispatch={dispatch}
                        selectedMode={selectedMode}
                        suppliers={allSuppliers}
                        suppliersPerNomenclature={suppliersPerNomenclature}
                        supplierState={color.supplierState}
                        allowedNomenclaturesBySupplier={
                          allowedNomenclaturesBySupplier
                        }
                      />
                      {nomenclaturesByColor[color.color_code].map(nom => {
                        return (
                          <React.Fragment key={nom.nomenclature_id}>
                            <NomenclatureRow
                              commodity={selectedCommodity}
                              nom={nom}
                              selectedSupplier={selectedSupplier}
                              selectedManufacturer={selectedManufacturer}
                              dispatch={dispatch}
                              selectedMode={selectedMode}
                              suppliers={allSuppliers}
                              suppliersPerNomenclature={
                                suppliersPerNomenclature
                              }
                              supplierState={nom.supplierState}
                              allowedNomenclaturesBySupplier={
                                allowedNomenclaturesBySupplier
                              }
                            />
                            <TransitionGroup component={null}>
                              {_(suppliersPerNomenclature)
                                .get(nom.nomenclature_id, [])
                                .map(supplier => {
                                  const { supplier_id, id } = supplier;
                                  return (
                                    <CSSTransition
                                      timeout={500}
                                      classNames="supplierRowTrans"
                                      key={id}
                                    >
                                      <SupplierRow
                                        allSuppliersById={allSuppliersById}
                                        allManufacturersById={allManufacturersById}
                                        supplierId={supplier_id}
                                        supplier={supplier}
                                        commodityCode={selectedCommodity.code}
                                        nomenclature={nom}
                                        dispatch={dispatch}
                                        suppliers={allSuppliers}
                                        allowedNomenclaturesBySupplier={
                                          allowedNomenclaturesBySupplier
                                        }
                                        suppliersPerNomenclature={
                                          suppliersPerNomenclature
                                        }
                                      />
                                    </CSSTransition>
                                  );
                                })}
                            </TransitionGroup>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </section>

          <section className="sticky-container">
            <div className={s.suppliers}>
              <div className={s.suppliersHeader}>
                Dodavatelé
                <div className="flexWrap">
                  <button
                    className={`${s.buttonWithTextAndIcon} mr-16`}
                    onClick={() => dispatch(undoState())}
                  >
                    Krok zpět{" "}
                    <CornerDownLeft color="#445E9C" className={s.btnIcon} />
                  </button>
                  <button
                    className={classNames(s.buttonWithTextAndIcon, {
                      [s.selectedRemoveAllSuppliersBtn]:
                        selectedMode === MODES.REMOVE_ALL
                    })}
                    onClick={() => {
                      dispatch(toggleAllSuppliersRemoval());
                    }}
                  >
                    Mazat <Trash2 className={s.btnIcon} />
                  </button>
                </div>
              </div>
              <div className={s.suppliersContent}>
                <div className={s.suppliersContentDesc}>
                  Vyberte dodavatele, kterého chcete přiřazovat
                </div>
                <div>
                  {allSuppliers.map(({ name: supplierName, id: supplierId, Manufacturers }) => {
                    return (
                      <div key={`supplier-${supplierId}`}>
                        <h3>{supplierName} {suppliersCount[supplierId]}</h3>
                        {Manufacturers.map(({ name, id }) => (
                          <div key={id} className={s.supplierButtons}>
                            <button
                              className={classNames(s.selectSupplierBtn, {
                                [s.selectedManufacturer]: id === selectedManufacturer
                              })}
                              onClick={() => dispatch(toggleSupplierSelection(supplierId, id))}
                            >
                              <div>
                                {name}{" "}
                                {isSupplierSelectedForRemoval(
                                  supplierId,
                                  selectedSupplier,
                                  selectedMode,
                                  id,
                                  selectedManufacturer,
                                ) && <span>(MAZÁNÍ)</span>}
                              </div>
                              <div>{manufacturersCount[id] || 0}</div>
                            </button>
                            <button
                              className={classNames(s.supplierBtnRemove, {
                                [s.selectedManufacturerRemoval]: isSupplierSelectedForRemoval(
                                  supplierId,
                                  selectedSupplier,
                                  selectedMode,
                                  id,
                                  selectedManufacturer,
                                )
                              })}
                              onClick={() => dispatch(toggleSupplierRemoval(id, supplierId))}
                            >
                              <Trash2 />
                            </button>
                          </div>
                        ))}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({
  errors: state.errors,
  fetching: state.fetching,
  mutations: state.mutations,
  data: prepareNomenclaturesDataForSupplier(state),
  router: state.router,
  selectedCommodity: state.selectedCommodity,
  shoppingCart: state.shoppingCart,
  suppliers: state.suppliers,
  suppliersOverview: state.suppliersOverview
}))(withRouter(Supplier));

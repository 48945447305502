import * as types from "../actions/actionTypes";
import produce from "immer";
import _ from "lodash";
import {
  batchSaveSupplierHeader,
  deduplicateNomenclatureSuppliers
} from "../actions/suppliers";

const initialState = {
  selectedMode: null,
  selectedSupplier: null,
  suppliersPerNomenclature: {},
  suppliersSumPerNomenclature: {},
  suppliersCount: {},
  allSuppliers: [],
  allSuppliersById: {},
  allManufacturersById: {},
  undoStates: []
};

function reverseItems(state) {
  return (acc, si) => {
    const suppliers = state.suppliersPerNomenclature[si.nomenclature_id] || [];
    const currentSupplier =
      suppliers.find(sup => sup.supplier_id === si.supplier_id) || {};
    currentSupplier.amount
      ? acc.updateItems.push({
          nomenclature_id: si.nomenclature_id,
          supplier_id: si.supplier_id,
          manufacturer_id: si.manufacturer_id,
          amount: currentSupplier.amount
        })
      : acc.deleteItems.push({
          nomenclature_id: si.nomenclature_id,
          supplier_id: si.supplier_id,
          manufacturer_id: si.manufacturer_id,
        });
    return acc;
  };
}

const suppliers = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case types.TOGGLE_SUPPLIER_SELECTION: {
        const notAlreadySelected = draft.selectedSupplier !== action.supplier || draft.selectedManufacturer !== action.manufacturer;
        draft.selectedMode = notAlreadySelected ? "selection" : null;
        draft.selectedSupplier = notAlreadySelected ? action.supplier : null;
        draft.selectedManufacturer = notAlreadySelected ? action.manufacturer : null;
        return draft;
      }

      case types.TOGGLE_SUPPLIER_REMOVAL: {
        const isAlreadySelected =
          draft.selectedMode === "removal" &&
          draft.selectedSupplier === action.supplier &&
          draft.selectedManufacturer === action.manufacturer;
        draft.selectedMode = isAlreadySelected ? null : "removal";
        draft.selectedSupplier = isAlreadySelected ? null : action.supplier;
        draft.selectedManufacturer = isAlreadySelected ? null : action.manufacturer;
        return draft;
      }

      case types.TOGGLE_ALL_SUPPLIERS_REMOVAL: {
        draft.selectedMode =
          draft.selectedMode === "all-removal" ? null : "all-removal";
        draft.selectedSupplier = null;
        draft.selectedManufacturer = null;
        return draft;
      }

      case types.CHANGE_SUPPLIER_COUNT: {
        const amount = parseInt(action.amount, 10);
        draft.suppliersPerNomenclature[action.nomenclature].forEach(sup => {
          if (sup.supplier_id === action.supplier) sup.amount = amount;
        });
        return draft;
      }

      case types.FETCH_SUPPLIERS_REQUEST:
        draft.allSuppliers = [];
        draft.allSuppliersById = {};
        draft.allManufacturersById = {};
        return draft;

      case types.FETCH_SUPPLIERS_SUCCESS:
        draft.allSuppliers = action.response;
        draft.allSuppliersById = action.response.reduce((acc, sup) => {
          acc[sup.id] = sup;

          sup.Manufacturers.forEach(m => {
            draft.allManufacturersById[m.id] = m
          })

          return acc;
        }, {});
        draft.allowedNomenclaturesBySupplier = action.response.reduce(
          (acc, sup) => {
            const nomenclatures = sup.Nomenclatures || [];
            acc[sup.id] = new Set(nomenclatures.map(n => n.nomenclature_id));
            return acc;
          },
          {}
        );
        return draft;

      case types.FETCH_SUPPLIER_HEADER_SUCCESS:
        const supplierItems = action.response.items;
        const suppliersPerNomenclature = _(supplierItems).groupBy(
          "nomenclature_id"
        );
        const supplierItemsById = supplierItems.reduce((acc, si) => {
          acc[si.id] = si;
          return acc;
        }, {});
        const suppliersSumPerNomenclature = suppliersPerNomenclature
          .reduce((acc, sups, nomId) => {
            return acc.set(nomId, sups.reduce((acc, s) => acc + s.amount, 0));
          }, _({}))
          .value();
        const suppliersCount = _(supplierItems)
          .reduce(
            (acc, si) =>
              acc.update([si.supplier_id], count => (count ? count + 1 : 1)),
            _({})
          )
          .value();
        const manufacturersCount = _(supplierItems)
          .reduce(
            (acc, si) =>
              acc.update([si.manufacturer_id], count => (count ? count + 1 : 1)),
            _({})
          )
          .value();
        draft.suppliersPerNomenclature = suppliersPerNomenclature.value();
        draft.supplierItemsById = supplierItemsById;
        draft.suppliersSumPerNomenclature = suppliersSumPerNomenclature;
        draft.suppliersCount = suppliersCount;
        draft.manufacturersCount = manufacturersCount;
        return draft;

      case types.BATCH_SAVE_SUPPLIER_HEADER_REQUEST: {
        if (action.undo) return draft;

        const updatedItems = action.updateItems.reduce(reverseItems(state), {
          updateItems: [],
          deleteItems: []
        });
        const deletedItems = action.deleteItems.reduce(reverseItems(state), {
          updateItems: [],
          deleteItems: []
        });
        draft.undoStates.push(
          batchSaveSupplierHeader(
            action.commodityCode,
            updatedItems.updateItems.concat(deletedItems.updateItems),
            updatedItems.deleteItems.concat(deletedItems.deleteItems),
            { undo: true }
          )
        );
        return draft;
      }

      case types.BATCH_REMOVE_SUPPLIER_HEADER_REQUEST: {
        if (action.undo) return draft;

        const currentItems = action.items
          .map(si => {
            const suppliers =
              state.suppliersPerNomenclature[si.nomenclature_id] || [];
            const currentSupplier = suppliers.find(
              sup => sup.supplier_id === si.supplier_id
            );
            return (
              currentSupplier && {
                nomenclature_id: si.nomenclature_id,
                supplier_id: si.supplier_id,
                manufacturer_id: si.manufacturer_id,
                amount: currentSupplier.amount
              }
            );
          })
          .filter(item => item);
        draft.undoStates.push(
          batchSaveSupplierHeader(action.commodityCode, currentItems, [], {
            undo: true
          })
        );
        return draft;
      }

      case types.SAVE_SUPPLIER_ITEM_REQUEST:
        const {
          amount,
          nomenclature_id,
          supplier_id,
          manufacturer_id,
        } = draft.supplierItemsById[action.itemId];

        draft.undoStates.push(
          batchSaveSupplierHeader(
            action.commodityCode,
            [
              {
                nomenclature_id: nomenclature_id,
                supplier_id: supplier_id,
                manufacturer_id: manufacturer_id,
                amount: amount,
              }
            ],
            [],
            {
              undo: true
            }
          )
        );
        return draft;

      case types.DUPLICATE_SUPPLIER_ITEM_SUCCESS:
        draft.undoStates.push(
          deduplicateNomenclatureSuppliers(
            action.commodityCode,
            action.response.id,
            action.supplierId
          )
        );
        return draft;

      case types.SUPPLIERS_POP_STATE:
        draft.undoStates.pop();
        return draft;

      case types.SUPPLIERS_CLEAR_STATE:
        return initialState;

      default:
        return draft;
    }
  });
};

export default suppliers;

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Popup from "react-popup";
import { AlertOctagon, Check, ChevronLeft, Edit2, Layers, RefreshCcw } from "react-feather";

import { dumpError } from "../lib/utils";
import { countsByState } from "../lib/shoppingCart";
import { trackEvent } from "../lib/ga";
import * as KeboolaActions from "../actions/keboola";
import * as EfficiencyActions from "../actions/efficiency";
import * as AuthActions from "../actions/authentication";

import GlobalErrors from "./GlobalErrors";

import "./ShoppingCart.css";

export const ShoppingCartState = ({ shoppingCart, isFetching, error }) => {
  const { inProgress, commoditiesLeft, finished } = countsByState(shoppingCart);
  let content;
  if (error) {
    content = dumpError(error);
  } else if (!isFetching) {
    content = (
      <React.Fragment>
        <div className="iconCount">
          <Edit2 className="icon" color="#ffaf00" />
          {inProgress}
        </div>
        <div className="iconCount">
          <Check className="icon" color="#009845" />
          {finished}
        </div>
        <div className="iconCount">
          <Layers className="icon" color="#2e374d" />
          {commoditiesLeft}
        </div>
      </React.Fragment>
    );
  } else {
    content = "";
  }

  return content;
};

function ImportDataConfirmPopup({ importData }) {
  return (
    <div className="ImportDataConfirmPopup">
      <div className="heading">
        <div className="iconWrap">
          <RefreshCcw className="icon" size="20" color="#FFF" />
        </div>
        <div className="title">Aktualizace dat predikcí</div>
        <div className="close" onClick={() => Popup.close()}>
          Zavřít
        </div>
      </div>
      <div className="body">
        <div className="exclamationMarkIcon">!</div>
        <p className="warningText">
          Opravdu chcete aktualizovat data predikci? Vámi vyplněné hodnoty
          zůstanou zachovány.
        </p>
        <button
          onClick={() => {
            trackEvent("import-keboola-data", "update");
            importData();
            Popup.close();
          }}
        >
          Aktualizovat
        </button>
      </div>
    </div>
  );
}

function ImportDataSection({ importData, getSession, keboola, fetching }) {
  // console.log(keboola)
  const inProgress =
    keboola &&
    (keboola.status === "waiting" || keboola.status === "processing");
  const failed = keboola && keboola.status === "error";
  let content;

  if (inProgress)
    content = (
      <>
        <button
          className="importDataBtn btn-text-small"
          disabled
        >
          {fetching.keboola ? '...' : 'Probíhá aktualizace dat. Akce může trvat 3 - 10 minut.'}
        </button>
        <button
          className="importDataBtn"
          style={{ marginLeft: '-16px' }}
          title="Zjistit aktuální stav"
          onClick={() => getSession()}
        >
          <RefreshCcw />
        </button>
      </>
    );
  else if (failed)
    content = (
      <React.Fragment>
        <button
          className="importDataBtn"
          onClick={() =>
            Popup.create({
              content: <ImportDataConfirmPopup importData={importData} />
            })
          }
        >
          Aktualizovat data
        </button>
        <div
          className='btn-suffix'
          title={`Poslední aktualizace z ${moment(keboola.created_at).format("D. M. YYYY")} selhala. Zkuste to znovu.`}
        >
          <AlertOctagon style={{ color: '#dd3333' }} />
        </div>
        {/*
          <p>
            Poslední aktualizace z{" "}
            {moment(keboola.created_at).format("D. M. YYYY")}{" "}
            <strong>selhala</strong>. Zkuste to znovu.
          </p>
        */}
      </React.Fragment>
    );
  else
    content = (
      <React.Fragment>
        <button
          className="importDataBtn"
          onClick={() =>
            Popup.create({
              content: <ImportDataConfirmPopup importData={importData} />
            })
          }
        >
          Aktualizovat data
        </button>
        {keboola && keboola.finished_at && (
          <div
            className='btn-suffix'
            title={`Poslední aktualizace dat ${moment(keboola.finished_at).format("D. M. YYYY HH:mm")}`}
          >
            <Check style={{ color: '#33dd33' }} />
          </div>
          /*<p>
            Poslední aktualizace dat{" "}
            {moment(keboola.finished_at).format("D. M. YYYY HH:mm")}
          </p>*/
        )}
      </React.Fragment>
    );

  return <div className="importData">{content}</div>;
}


function CheckEfficiency({ getSession, checkEfficiency, efficiency, fetching }) {
    const inProgress =
        efficiency &&
        (efficiency.status === "waiting" || efficiency.status === "processing");
    const failed = efficiency && efficiency.status === "error";
    let content;

    if (inProgress)
        content = (
            <React.Fragment>
                <button
                    className="importDataBtn btn-text-small"
                    disabled
                >
                    {fetching.session ? '...' : 'Probíhá kontrola výstačnosti... (cca 5 minut)'}
                </button>
                <button
                  className="importDataBtn"
                  style={{ marginLeft: '-16px' }}
                  title="Zjistit stav kontroly výstačnosti"
                  onClick={() => getSession()}
                >
                  <RefreshCcw />
                </button>
            </React.Fragment>
        );
    else if (failed)
        content = (
            <React.Fragment>
                <button
                    className="importDataBtn"
                    onClick={() =>{
                        checkEfficiency()
                    }}
                >
                    Zkontrolovat výstačnost
                </button>
                <div
                  className='btn-suffix'
                  title="Poslední kontrola selhala. Zkuste to znovu."
                >
                  <AlertOctagon style={{ color: '#dd3333' }} />
                </div>
            </React.Fragment>
        );
    else
        content = (
            <React.Fragment>
                <button
                    className="importDataBtn"
                    onClick={() =>
                        checkEfficiency()
                    }
                >
                    Zkontrolovat výstačnost
                </button>
                {efficiency && efficiency.finished_at && (
                    <div
                      className='btn-suffix'
                      title={`Poslední kontola výstačnosti ${moment(efficiency.finished_at).format("D. M. YYYY HH:mm")}`}
                    >
                      <Check style={{ color: '#33dd33' }} />
                    </div>
                )}
            </React.Fragment>
        );

    return <div className="importData">{content}</div>;
}

const ShoppingCart = ({
  shoppingCart,
  isFetching,
  error,
  fetching,
  showBackLink,
  keboola,
  importData,
  checkEfficiency,
  getSession,
  efficiency
}) => {
  return (
    <>
      <section className="ShoppingCart">
        <div className="ShoppingCartInner fixedMaxSize">
          {showBackLink ? (
            <Link className="backLink" to="/">
              <ChevronLeft className="backIcon" size="24" />
              Zpět na výběr komodit
            </Link>
          ) : (
            <>
                <ImportDataSection
                  importData={importData}
                  keboola={keboola.import}
                  getSession={getSession}
                  fetching={fetching}
                />
                <CheckEfficiency
                  getSession={getSession}
                  checkEfficiency={checkEfficiency}
                  efficiency={efficiency}
                  fetching={fetching}
                />
            </>
          )}
          <div className="cartStats">
            <ShoppingCartState
              shoppingCart={shoppingCart}
              isFetching={isFetching}
              error={error}
            />
            <Link to="/shopping-cart" className="shoppingCartBtn">
              Nákupní košík
            </Link>
          </div>
        </div>
      </section>
      <GlobalErrors />
    </>
  );
};

export default connect(
  state => ({
    keboola: state.keboola,
    efficiency: state.efficiency,
    fetching: state.fetching,
  }),
  dispatch => ({
    importData: () => dispatch(KeboolaActions.importData()),
    checkEfficiency: () => dispatch(EfficiencyActions.checkEfficiency()),
    getSession: () => dispatch(AuthActions.session())
  })
)(ShoppingCart);

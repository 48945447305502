import {
  batchRemoveSupplierHeader,
  batchSaveSupplierHeader
} from "../../actions/suppliers";
import _ from "lodash";
import { flatten } from "../../lib/utils";
import { intersection } from "../../lib/set";

export const MODES = {
  SELECTION: "selection",
  REMOVAL: "removal",
  REMOVE_ALL: "all-removal"
};

// called only during removal
function updateSuppliersOnNomenclatures(
  nomenclatures,
  commodityCode,
  selectedSupplier,
  suppliersPerNomenclature,
  selectedManufacturer,
) {
  console.log({nomenclatures, selectedSupplier, selectedManufacturer})
  const payload = nomenclatures.reduce(
    (acc, nom) => {

      const suppliers = suppliersPerNomenclature[nom.nomenclature_id] || [];
      console.log({nom, suppliers})
      if (suppliers.length) {
        const restSuppliers = suppliers.filter(
          sup => sup.manufacturer_id !== selectedManufacturer
        );
        if (suppliers.length !== restSuppliers.length && restSuppliers.length === 1) {
          restSuppliers
            .filter(sup => sup.manufacturer_id !== selectedManufacturer)
            .forEach(sup =>
              acc.update.push({
                nomenclature_id: nom.nomenclature_id,
                supplier_id: sup.supplier_id,
                // manufacturer_id: selectedManufacturer,
                manufacturer_id: sup.manufacturer_id,
                amount: nom.for_order
              })
            );
        }
      }

      acc.delete.push({
        nomenclature_id: nom.nomenclature_id,
        supplier_id: selectedSupplier,
        manufacturer_id: selectedManufacturer,
      });
      return acc;
    },
    { update: [], delete: [] }
  );
  console.log({commodityCode, payload })
  return batchSaveSupplierHeader(commodityCode, payload.update, payload.delete);
}

// apply to the whole commodity (all colors, all nomenclatures)
// commodity -> color -> nomenclature
export function commodityOnClick(
  selectedMode,
  selectedSupplier,
  commodityCode,
  nomenclatures,
  suppliersPerNomenclature,
  allowedNomenclaturesBySupplier,
  selectedManufacturerId,
) {
  switch (selectedMode) {
    case MODES.SELECTION:
      return addSupplierToNomenclatures(
        nomenclatures,
        suppliersPerNomenclature,
        selectedSupplier,
        commodityCode,
        allowedNomenclaturesBySupplier,
        selectedManufacturerId,
      );
    case MODES.REMOVAL:
      return updateSuppliersOnNomenclatures(
        nomenclatures,
        commodityCode,
        selectedSupplier,
        suppliersPerNomenclature,
        selectedManufacturerId,
      );
    case MODES.REMOVE_ALL:
      const items = _(suppliersPerNomenclature)
        .values()
        .flatten()
        .map(si => ({
          nomenclature_id: si.nomenclature_id,
          supplier_id: si.supplier_id,
          manufacturer_id: si.manufacturer_id,
        }));
      return batchRemoveSupplierHeader(commodityCode, items);
    default:
      return null;
  }
}

// click on color group (all nomenclatures of a given color)
// commodity -> color -> nomenclature
export function colorGroupOnClick(
  selectedMode,
  commodityCode,
  nomenclatures,
  selectedSupplier,
  suppliersPerNomenclature,
  allowedNomenclaturesBySupplier,
  selectedManufacturer
) {
  switch (selectedMode) {
    case MODES.SELECTION:
      return addSupplierToNomenclatures(
        nomenclatures,
        suppliersPerNomenclature,
        selectedSupplier,
        commodityCode,
        allowedNomenclaturesBySupplier,
        selectedManufacturer,
      );
    case MODES.REMOVAL:
      return updateSuppliersOnNomenclatures(
        nomenclatures,
        commodityCode,
        selectedSupplier,
        suppliersPerNomenclature,
        selectedManufacturer,
      );
    case MODES.REMOVE_ALL:
      return batchRemoveSupplierHeader(
        commodityCode,
        _(nomenclatures)
          .map(n => n.nomenclature_id)
          .filter(nomId => suppliersPerNomenclature[nomId])
          .map(nomId =>
            suppliersPerNomenclature[nomId].map(si => ({
              nomenclature_id: nomId,
              supplier_id: si.supplier_id,
              manufacturer_id: si.manufacturer_id,
            }))
          )
          .flatten()
      );
    default:
      return null;
  }
}

// click on nomenclature
// commodity -> color -> nomenclature
export function nomenclatureOnClick(
  selectedMode,
  commodityCode,
  nomenclature,
  selectedSupplierId,
  selectedManufacturerId,
  suppliersPerNomenclature,
  allowedNomenclaturesBySupplier
) {
  const nomId = nomenclature.nomenclature_id;
  const suppliers = suppliersPerNomenclature[nomId];
  switch (selectedMode) {
    case MODES.SELECTION:
      return addSupplierToNomenclature(
        nomenclature,
        selectedSupplierId,
        suppliers,
        commodityCode,
        allowedNomenclaturesBySupplier,
        selectedManufacturerId,
      );
    case MODES.REMOVAL:
      const itemToRemove = suppliers.find(sup => sup.supplier_id === selectedSupplierId && sup.manufacturer_id === selectedManufacturerId)
      if (!itemToRemove) return console.log('no item for possible removal')

      if (suppliers.length === 2) {
        const restSuppliers = suppliers.filter(
          sup => sup.supplier_id !== selectedSupplierId
        );
        return batchSaveSupplierHeader(
          commodityCode,
          [
            _.update(
              restSuppliers[0],
              "amount",
              amount =>
                amount +
                suppliers.find(s => s.supplier_id === selectedSupplierId).amount
            )
          ],
          [
            {
              nomenclature_id: nomId,
              supplier_id: selectedSupplierId,
              manufacturer_id: selectedManufacturerId,
            }
          ]
        );
      } else {
        return batchRemoveSupplierHeader(commodityCode, [
          {
            nomenclature_id: nomId,
            supplier_id: selectedSupplierId,
            manufacturer_id: selectedManufacturerId,
          }
        ]);
      }
    case MODES.REMOVE_ALL:
      return batchRemoveSupplierHeader(
        commodityCode,
        _(suppliersPerNomenclature)
          .get(nomId, [])
          .map(si => ({
            nomenclature_id: nomId,
            supplier_id: si.supplier_id,
            manufacturer_id: si.manufacturer_id,
          }))
      );
    default:
      return null;
  }
}

export function addSupplierToNomenclatures(
  nomenclatures,
  suppliersPerNomenclature,
  selectedSupplier,
  commodityCode,
  allowedNomenclaturesBySupplier,
  selectedManufacturerId,
) {
  const nomenclaturesForUpdate = nomenclatures.filter(nom => {
    const suppliers = suppliersPerNomenclature[nom.nomenclature_id] || [];
    const suppliersSelected = suppliers.map(
      sup => sup.supplier_id === selectedSupplier
    );
    // console.log('?', {suppliersSelected})
    return (
      nom.for_order > 0 &&
      !suppliersSelected.includes(true) &&
      allowedNomenclaturesBySupplier[selectedSupplier].has(nom.nomenclature_id)
    );
  });
  const newItems = nomenclaturesForUpdate.map(nom =>
    supplierToNomenclaturesItems(
      nom,
      selectedSupplier,
      suppliersPerNomenclature[nom.nomenclature_id] || [],
      commodityCode,
      selectedManufacturerId,
    )
  );
  return batchSaveSupplierHeader(commodityCode, flatten(newItems));
}

export function addSupplierToNomenclature(
  nomenclature,
  selectedSupplier,
  suppliers = [],
  commodityCode,
  allowedNomenclaturesBySupplier,
  selectedManufacturer,
) {
  const items = allowedNomenclaturesBySupplier[selectedSupplier].has(
    nomenclature.nomenclature_id
  )
    ? supplierToNomenclaturesItems(nomenclature, selectedSupplier, suppliers, null, selectedManufacturer)
    : [];
  return batchSaveSupplierHeader(commodityCode, items);
}

export function supplierToNomenclaturesItems(
  nomenclature,
  selectedSupplier,
  suppliers = [],
  commodityCode,
  manufacturerId
) {
  const nomId = nomenclature.nomenclature_id;
  const newSuppliersPerNomenclatureCnt =
    suppliers.filter(sup => (sup.supplier_id !== selectedSupplier || sup.manufacturer_id !== manufacturerId)).length + 1;

  console.log({newSuppliersPerNomenclatureCnt})
  if (newSuppliersPerNomenclatureCnt === 1) {
    return [
      {
        nomenclature_id: nomId,
        supplier_id: selectedSupplier,
        amount: nomenclature.for_order,
        manufacturer_id: manufacturerId,
      }
    ];
  } else if (newSuppliersPerNomenclatureCnt === 2) {
    const suppliersAmountSum = suppliers.reduce(
      (sum, sup) => sum + sup.amount,
      0
    );
    const firstSupplierAmount =
      suppliersAmountSum < nomenclature.for_order && suppliersAmountSum;
    const amountForSplitting =
      suppliersAmountSum < nomenclature.for_order
        ? nomenclature.for_order - suppliersAmountSum
        : nomenclature.for_order / 2;

    return suppliers
      .map(si => ({
        nomenclature_id: nomId,
        supplier_id: si.supplier_id,
        manufacturer_id: si.manufacturer_id,
        amount:
          firstSupplierAmount ||
          Math.ceil(nomenclature.for_order / nomenclature.pices_in_box / 2) *
            nomenclature.pices_in_box
      }))
      .concat({
        nomenclature_id: nomId,
        supplier_id: selectedSupplier,
        manufacturer_id: manufacturerId,
        amount:
          Math.floor(amountForSplitting / nomenclature.pices_in_box) *
          nomenclature.pices_in_box
      });
  } else {
    return [
      {
        nomenclature_id: nomId,
        supplier_id: selectedSupplier,
        manufacturer_id: manufacturerId,
        amount: 0
      }
    ];
  }
}

export function filterAllowedSuppliers(
  nomenclatures,
  suppliers,
  allowedNomenclaturesBySupplier
) {
  const nomenclatureIds = new Set(nomenclatures.map(n => n.nomenclature_id));
  return suppliers.filter(sup => {
    const nomenclaturesWithoutSupplier = nomenclatures.filter(
      nom =>
        nom.for_order > 0 &&
        allowedNomenclaturesBySupplier[sup.id].has(nom.nomenclature_id)
    );
    return (
      intersection(allowedNomenclaturesBySupplier[sup.id], nomenclatureIds)
        .size > 0 && nomenclaturesWithoutSupplier.length > 0
    );
  });
}

export function swapNomenclatureSuppliers({
  commodityCode,
  nomenclatureId,
  originSupplier,
  originManufacturer,
  targetSupplier,
  targetManufacturer,
  amount
}) {
  const createItems = [
    {
      nomenclature_id: nomenclatureId,
      supplier_id: targetSupplier,
      manufacturer_id: targetManufacturer,
      amount: amount,
    }
  ];
  const deleteItems = [
    {
      nomenclature_id: nomenclatureId,
      supplier_id: originSupplier,
      manufacturer_id: originManufacturer,
    }
  ];
  return batchSaveSupplierHeader(commodityCode, createItems, deleteItems);
}
